export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    align: 'center',
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    minWidth: 150
  },
  {
    title: '加油机名称',
    key: 'tanker_name',
    minWidth: 160
  },
  {
    title: '加油机设备编号',
    key: 'num',
    minWidth: 120
  },
  {
    title: '油枪编号',
    align: 'center',
    key: 'gun_num'
  },
  // {
  //   title: '绑定油罐号',
  //   key: 'tank_num'
  // },
  {
    title: '油品',
    align: 'center',
    key: 'type'
  },
  {
    title: '监控微处理号',
    key: 'cpu_code',
    minWidth: 104
  },
  {
    title: '编码器号',
    // fixed: 'right',
    align: 'center',
    key: 'code_num',
    minWidth: 120
  },
  {
    title: '操作',
    fixed: 'right',
    key: 'actions',
    align: 'center',
    width: 100,
    // extra: [{ label: '详情', name: 'edit' }]
    scopedSlots: {
      customRender: 'actions'
    }
  }
];

export const formDesc = {
  site_name: {
    type: 'input',
    label: '加油站名称',
    layout: 12
  },
  tanker_name: {
    type: 'input',
    label: '加油机名称',
    layout: 12
  },
  num: {
    type: 'input',
    label: '设备编号',
    layout: 12
  },
  gun_num: {
    type: 'input',
    label: '油枪编号',
    layout: 12
  },
  type: {
    type: 'input',
    label: '油品',
    layout: 12
  },
  cpu_code: {
    type: 'input',
    label: '监控微处理号',
    layout: 12
  },
  // gun_num2: {
  //   type: 'input',
  //   label: '主板内枪号',
  //   layout: 12
  // },
  code_num: {
    type: 'input',
    label: '编码器号',
    layout: 12
  }
};
