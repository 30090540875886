<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <div></div>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchParams.num"
            clearable
            placeholder="加油机设备编号"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchParams.cpu_code"
            clearable
            placeholder="监控微处理器号"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchParams.code_num"
            clearable
            placeholder="编码器号"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #actions="{ scope }">
        <el-button
          type="primary"
          size="small"
          @click="handleDetailRow(scope.row)"
          >详情</el-button
        >
      </template>
    </default-table>

    <!-- 油枪详情 -->
    <el-dialog
      title="油枪详情"
      :visible.sync="detailVisible"
      width="900px"
      custom-class="dialog"
      :close-on-click-modal="false"
    >
      <ele-form
        v-model="RowInfo"
        :form-desc="formDesc"
        labelPosition="right"
        :isShowSubmitBtn="false"
        :isShowBackBtn="false"
        :span="24"
        disabled
      ></ele-form>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import EleForm from 'vue-ele-form';
import { QueryTaxPorts, ExportTaxPorts } from '@/api/gas_info_mgr';

import { columns, formDesc } from './columns/gun';
import { getStorage } from '@/utils/storage';
import mixins from '@/mixins';

export default {
  components: {
    PageHeader,
    DefaultTable,
    EleForm
  },
  data() {
    return {
      level: getStorage('level'),
      tableLoading: true,
      gas: '',
      factory: '',
      searchParams: {
        site_id: '',
        num: '',
        cpu_code: '',
        code_num: ''
      },
      columns,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      detailVisible: false,
      operatingVisible: false,
      RowInfo: {},
      formDesc
    };
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryTaxPorts(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };
      ExportTaxPorts(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleDetailRow(data) {
      this.detailVisible = !this.detailVisible;
      this.RowInfo = data;
    }
  }
};
</script>

<style lang="scss" scoped></style>
